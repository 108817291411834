import { EnvironmentTypes } from './environment-types';

const domain = 'uat.finlistics-vm.com';
const backEnd = `https://api.${ domain }`;
const api = `${ backEnd }/api`;
const idp = `https://idp.${ domain }`;

export const environment = {
  type: EnvironmentTypes.Uat,
  production: true,

  po1Save: true,
  alwaysSegments: true,
  salesForceIntegration: true,
  analysisButtonsOnDashboard: false,

  legacySolutionManagement: true,
  userSolutionManagement: true,

  apiGateway: api,
  loginRedirect: `${ backEnd }/identity/identityprovider/authorize`,
  logoutRedirect: `${ backEnd }/identity/identityprovider/logout`,
  resetPassword: `${ idp }/Identity/Account/Manage/Password`,

  gtmId: 'GTM-5ZGHQS7',
  amplitudeKey: 'e4425b59b024373f5e5650268986f8a1',
  hotjarSiteId: null,
  allego: 'https://finlistics.gitbook.io/finlistics-docs/',
};
